import React, { useRef } from "react";
import "./style.css";
import backArrow from "../../Assets/backArrow.svg";
import nextArrow from "../../Assets/nextArrow.svg";
import isActiveButton from "../../Assets/isActiveButton.svg";
import isNotActiveButton from "../../Assets/isNotActiveButton.svg";
import { useState, useEffect } from "react";
import CardVideoUi from "../CardVideoUi";

export default function Main({ type }) {
  const listRef = useRef(null);
  const buttons = Array(8).fill(0);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const checkScrollPosition = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setIsStart(scrollLeft === 0);
      setIsEnd(scrollLeft + clientWidth === scrollWidth);
    }
  };

  const trilhaMerx = {
    title: "Trilha de conhecimento MERX",
    subtitle: "Comece por aqui",
    data: [
      {
        disponibilizada: true,
        linkAula: "https://www.youtube.com/watch?v=JQhD1X0X1jw",
        // nomePdf:
        //   "https://drive.google.com/file/d/1fgGWV4RTtgVrirqjIfZdIVTjSaP4Dywj/view",
        nomePdf: "",
        numAula: "1",
        titleAula: "Como encontrar o cliente",
        key: 1,
      },
      {
        disponibilizada: true,
        linkAula: "https://youtu.be/QwgVUC63G4I",
        // nomePdf:
        //   "https://drive.google.com/file/d/1KGHyr6PBDIrLDZtZYLMIwq9H5TOWyoJ_/view",
        nomePdf: "",
        numAula: "2",
        titleAula: "Como se aproximar do cliente",
        key: 2,
      },
      {
        disponibilizada: true,
        linkAula: "https://youtu.be/U2tcJhA_l3g",
        nomePdf: "",
        numAula: "3",
        titleAula: "Estudo de viabilidade | CATIVO X APE",
        key: 3,
      },
      {
        disponibilizada: true,
        linkAula: "https://youtu.be/CLACPPeNO1c",
        nomePdf: "",
        numAula: "4",
        titleAula: "Estudo de viabilidade | LIVRE X APE",
        key: 4,
      },
      {
        disponibilizada: false,
        linkAula: "https://youtu.be/hqAcpYeH-C0",
        nomePdf: "",
        numAula: "5",
        titleAula: "Estudo de viabilidade | GD X APE",
        key: 5,
      },
      {
        disponibilizada: false,
        linkAula: "https://youtu.be/IsvkvdkLkG4",
        nomePdf: "",
        numAula: "6",
        titleAula: `Estudo de viabilidade | CATIVO X LIVRE`,
        key: 6,
      },
      {
        disponibilizada: false,
        linkAula: "#",
        nomePdf: "",
        numAula: "7",
        titleAula: "Impacto de grandes projetos no seu portfolio",
        key: 7,
      },
      {
        disponibilizada: false,
        linkAula: "#",
        nomePdf: "",
        numAula: "8",
        titleAula: "Como realizar orçamentos de grandes usinas",
        key: 8,
      },
    ],
  };

  const trilhaCCEE = {
    title: "Trilha de conhecimento CCEE",
    subtitle:
      "Vídeos de introdução da CCEE, para saber mais busque o portal de aprendizagem da CCEE",
    data: [
      {
        disponibilizada: true,
        linkAula: "https://www.youtube.com/watch?v=sybqdCIsIsA",
        nomePdf: "",
        numAula: "1",
        titleAula: "O caminho da energia elétrica",
        key: 1,
      },
      {
        disponibilizada: true,
        linkAula: "https://www.youtube.com/watch?v=ViFksZEd6VY",
        nomePdf: "",
        numAula: "2",
        titleAula: "Diferenças entre o mundo físico e o mundo comercial",
        key: 2,
      },
      {
        disponibilizada: true,
        linkAula: "https://www.youtube.com/watch?v=L__8yEpD5Ok",
        nomePdf: "",
        numAula: "3",
        titleAula: "Estrutura do setor elétrico e a CCEE",
        key: 3,
      },
      {
        disponibilizada: true,
        linkAula: "https://www.youtube.com/watch?v=2QpspF_wXmY",
        nomePdf: "",
        numAula: "4",
        titleAula: "Comercialização de energia e seus participantes",
        key: 4,
      },
      {
        disponibilizada: true,
        linkAula: "https://www.youtube.com/watch?v=Hopd1Bw4gIY",
        nomePdf: "",
        numAula: "5",
        titleAula: "Primeiros passos do consumidor no mercado livre",
        key: 5,
      },
    ],
  };

  const trilhaDocumentos = {
    title: "Trilha de documentos MERX",
    subtitle: "Lista de documentos com diferentes tópicos",
    data: [
      {
        disponibilizada: true,
        linkAula:
          "https://drive.google.com/file/d/133F29CQsbmX6wtpXAt8Ji5ekVzsgyPak/view?usp=sharing",
        nomePdf: "",
        numAula: "",
        titleAula: "Janeiro Branco: saúde mental no setor elétrico",
        key: 1,
      },
      {
        disponibilizada: true,
        linkAula:
          "https://drive.google.com/file/d/1D4powqyNTVeh92LQPpcAxSOD9paqKhdT/view?usp=sharing",
        nomePdf: "",
        numAula: "",
        titleAula:
          "Como é possível consumir uma energia livre de encargos e vários impostos?",
        key: 2,
      },
    ],
  };

  const newScrollS = (index) => {
    setActiveIndex(index);
    if (listRef.current) {
      if (listRef.current) {
        const scrollAmount = listRef.current?.scrollWidth ? 200 * index : 0;
        listRef.current.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
      checkScrollPosition();
    }
  };

  const scrollToEnd = () => {
    const scrollAmount = listRef.current?.scrollWidth ? 200 : 0;
    if (listRef.current) {
      listRef.current.scrollTo({
        left: listRef.current.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
    checkScrollPosition();
  };

  const scrollToStart = () => {
    if (listRef.current) {
      if (listRef.current) {
        const scrollAmount = listRef.current?.scrollWidth ? 200 : 0;
        listRef.current.scrollTo({
          left: listRef.current.scrollLeft - scrollAmount,
          behavior: "smooth",
        });
      }
      checkScrollPosition();
    }
  };

  useEffect(() => {
    checkScrollPosition();
    // Listener para verificar rolagem
    if (listRef.current) {
      listRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [listRef.current?.scrollWidth]);

  const generateTrilha = (data) => {
    return (
      <div className="trilha">
        <h2>{data.title}</h2>
        <p>{data.subtitle}</p>

        <div className="videos">
          <button onClick={scrollToStart}>
            <img src={backArrow} alt="Botão de retorno" />
          </button>

          <ul ref={listRef} className="videosList">
            {data.data.map((trilhaItem) => (
              <li>
                <CardVideoUi
                  disponibilizada={trilhaItem.disponibilizada}
                  linkAula={trilhaItem.linkAula}
                  nomePdf={trilhaItem.nomePdf}
                  numAula={trilhaItem.numAula}
                  titleAula={trilhaItem.titleAula}
                  key={trilhaItem.key}
                />
              </li>
            ))}
          </ul>

          <span className="buttonsMobileTrilha">
            {data.data.map((button, index) => (
              <img
                src={index === activeIndex ? isActiveButton : isNotActiveButton}
                alt="Botão de passar"
                className="activeButton"
                onClick={() => newScrollS(index)}
              ></img>
            ))}
          </span>

          <button onClick={scrollToEnd}>
            <img src={nextArrow} alt="Botão de passar" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {type === "merx" ? (
        generateTrilha(trilhaMerx)
      ) : type === "ccee" ? (
        generateTrilha(trilhaCCEE)
      ) : type === "documentos" ? (
        generateTrilha(trilhaDocumentos)
      ) : (
        <></>
      )}
    </>
  );
}
